import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FaMinus, FaAngleUp } from 'react-icons/fa';
import styles from './CustomCollapse.module.scss';
import { useTranslation } from 'react-i18next';

export default function CustomCollapse({ children, title, openedDefault, customStyle }) {
  const { t } = useTranslation();
  const maxHeight = 180;

  const [icon, setIcon] = useState(<FaAngleUp />);
  const [isOpen, setIsOpen] = useState(openedDefault);
  const [realMaxHeight, setRealMaxHeight] = useState(0);
  const [contentMaxHeight, setContentMaxHeight] = useState(openedDefault ? 'none' : 0);

  const contentRef = useRef(null);

  const heightHandler = useCallback(
    node => {
      if (node) {
        const height = node.getBoundingClientRect().height;

        setRealMaxHeight(height);

        if (!isOpen) {
          setContentMaxHeight(0);
        } else if (height > maxHeight) {
          setContentMaxHeight(maxHeight);
        } else {
          setContentMaxHeight('none');
        }
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      setIcon(<FaAngleUp />);
      setContentMaxHeight(realMaxHeight > maxHeight ? maxHeight : 'none');
    } else {
      setIcon(<FaMinus />);
      setContentMaxHeight(0);
    }
  }, [isOpen, realMaxHeight]);

  const handleClick = () => {
    setIsOpen(prev => !prev);
  };

  return (
    <div className={customStyle ? customStyle : styles.container}>
      <div onClick={handleClick} className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
        <span className={styles.icon}>{icon}</span>
      </div>
      <div
        style={{ maxHeight: contentMaxHeight, overflow: 'hidden', transition: 'max-height 0.3s ease' }}
        className={styles.contentContainer}
      >
        <div ref={heightHandler}>{children}</div>
      </div>
      {realMaxHeight > maxHeight && isOpen && (
        <span
          className={styles.showMore}
          onClick={() => {
            setContentMaxHeight(prev => (prev === 'none' || prev > maxHeight ? maxHeight : 'none'));
          }}
        >
          {contentMaxHeight === 'none' ? '- ' + t('pagina_inicial.homepage_19') : '+ ' + t('pagina_inicial.homepage_5')}
        </span>
      )}
    </div>
  );
}

CustomCollapse.defaultProps = {
  openedDefault: true,
};
