import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'store';
import 'react-datepicker/dist/react-datepicker.css';
import './translations/i18n';
import './index.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import * as Sentry from '@sentry/react';

import App from './App/App';

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'hml') {
  Sentry.init({
    dsn: 'https://a6df34b02e24b15a1c981b0812876d53@o4505621957771264.ingest.us.sentry.io/4507313212358656',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint?.originalException;

      if (error && error.isAxiosError && error.response && error.response.status === 401) {
        return null;
      }
      if (error && error.message === 'Network Error') {
        return null;
      }

      return event;
    },
  });
}

// store.subscribe(() => {
//   localStorage.setItem('reduxState', JSON.stringify(store.getState()));
// });

if (window.location?.href?.toLowerCase()?.includes('web.rededofutebol.com.br')) {
  window.location.href = 'https://web.footlink.app';
}

const app = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
