import Endpoints from 'endpoints';
import axios from 'api';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { stringifyFilters } from 'shared/utility';

export const actionTypes = {
  GET: 'club/GET',
  GET_SUCCESS: 'club/GET_SUCCESS',
  GET_FAILED: 'club/GET_FAILED',
  GET_ERROR_HANDLED: 'club/GET_ERROR_HANDLED',
  RESET: 'club/RESET',
  GET_SUCCESS_WITH_CONTRACT: 'club/GET_SUCCESS_WITH_CONTRACT',
};

const initialState = {
  nationalClubsPage: {
    loading: false,
    error: null,
    data: {
      count: 0,
      teams: [],
      hasNext: true,
      hasPrevious: false,
    },
    dataWithContract: {
      count: 0,
      teams: [],
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, nationalClubsPage: { ...state.nationalClubsPage, loading: true } };
    case actionTypes.GET_SUCCESS:
      return {
        ...state,
        nationalClubsPage: {
          ...state.nationalClubsPage,
          loading: false,
          data: action.payload.data,
          hasNext: action.payload.hasNext,
          hasPrevious: action.payload.hasPrevious,
        },
      };
    case actionTypes.GET_SUCCESS_WITH_CONTRACT:
      return {
        ...state,
        nationalClubsPage: {
          ...state.nationalClubsPage,
          loading: false,
          dataWithContract: action.payload.data,
        },
      };
    case actionTypes.GET_FAILED:
      return {
        ...state,
        nationalClubsPage: {
          ...state.nationalClubsPage,
          loading: false,
          error: action.payload.error,
        },
      };
    case actionTypes.GET_ERROR_HANDLED:
      return { ...state, nationalClubsPage: { ...state.nationalClubsPage, error: null } };
    case actionTypes.RESET:
      return {
        ...state,
        nationalClubsPage: {
          data: initialState.nationalClubsPage.data,
          loading: initialState.nationalClubsPage.loading,
          error: initialState.nationalClubsPage.error,
          dataWithContract: state.nationalClubsPage.dataWithContract,
        },
      };

    default:
      return state;
  }
}

export const Creators = {
  getNationalClubs: filters => async dispatch => {
    try {
      dispatch({ type: actionTypes.GET });

      const url = `${Endpoints.teams}`;
      const queryString = stringifyFilters(filters);

      const { data } = await axios.get(`${url}${queryString}`);

      const teams = data.results.map(team => ({
        id: team?.id,
        name: team?.nickname,
        emblem: team?.emblem,
        state: team?.state,
        city: team?.city,
        division: team?.division,
        professionalSquad: team?.counts?.athletes?.all,
        marketValue: team?.market_value,
        stadium: team?.profile?.stadium,
        country: team?.country,
      }));

      if (filters.has_footlink_contract) {
        dispatch({
          type: actionTypes.GET_SUCCESS_WITH_CONTRACT,
          payload: {
            data: {
              count: data.count,
              teams,
            },
          },
        });
      } else {
        dispatch({
          type: actionTypes.GET_SUCCESS,
          payload: {
            data: {
              count: data.count,
              teams,
              hasNext: data.next ? true : false,
              hasPrevious: data.previous ? true : false,
            },
          },
        });
      }
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response) {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: 'erros.text1' },
        });
      } else {
        dispatch({
          type: actionTypes.GET_FAILED,
          payload: { error: ErrorMessages.serviceUnavailable },
        });
      }
    }
  },
  resetNationalClubs: () => ({ type: actionTypes.RESET }),
};
