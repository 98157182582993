import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Creators } from 'store/ducks/nationalClubs';
import { withRouter } from 'react-router-dom';
import { getDivision } from 'shared/utility';
import { Card, Spinner, Table, Pagination } from 'components/template';
import TeamRow from 'components/TeamRow/TeamRow';
import styles from './ClubsList.module.scss';
import { usePagination } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { MdVerified } from 'react-icons/md';

function ClubsList({ division, searchName, filterMenu, footlink, clubsFootlink }) {
  const { t } = useTranslation();
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const nationalClubs = useSelector(state => state.nationalClubs.nationalClubsPage);
  const footlinkClubs = useSelector(state => state.nationalClubs.nationalClubsPage.dataWithContract);

  const [pagination, setPagination] = usePagination();

  useEffect(() => {
    if (!footlink && footlinkClubs.count <= 0) {
      let filters = {
        has_footlink_contract: true,
        limit: 999,
      };
      dispatch(Creators.getNationalClubs(filters));
    }
  }, [dispatch, footlink, footlinkClubs]);

  useEffect(() => {
    let filters = {
      q: searchName,
      state: filterMenu.currentState,
      has_footlink_contract: filterMenu.has_footlink_contract,
      country: filterMenu.country,
      division: filterMenu.division,
      ...(!clubsFootlink && { foreign: false }),
      ...pagination,
    };

    if (footlink) filters.limit = 999;

    if (division) filters.division = division;

    dispatch(Creators.getNationalClubs(filters));

    return () => {
      dispatch(Creators.resetNationalClubs());
    };
  }, [dispatch, division, searchName, filterMenu, pagination, footlink]);

  const rawData = footlink ? footlinkClubs.teams : nationalClubs.data.teams;

  const data =
    rawData &&
    rawData.map(row => {
      const isTeamVerified = footlink || footlinkClubs.teams.find(team => team.id === row.id);
      const team = (
        <>
          {isTeamVerified ? (
            <div className={styles.verified} footlink-title-right={t('home.footlinkClient')}>
              <MdVerified />
            </div>
          ) : (
            <span className={styles.notVerified} />
          )}
          <TeamRow name={row.name} id={row.id} img={row.emblem} />
        </>
      );
      const country = (
        <img className={styles.countryFlag} src={row.country?.flag} alt={`Bandeira ${row.country?.name}`} />
      );
      const state = row.state;
      // const city = row.city;
      const division = getDivision(row.division) + (row.division ? t('home.text3') : '');
      const professionalSquad = row.professionalSquad;
      // const marketValue = row.marketValue;
      const stadium = row.stadium;
      // const seeProfessionalCastButton =
      //   <Button smaller={true}>
      //     <Link className={styles.link} to={`/clube/${row.id}/elenco`}>
      //       Ver elenco
      //     </Link>
      //   </Button>;
      return [
        team,
        country,
        state,
        ...(user.permission_gender !== 'female' ? [division] : []),
        professionalSquad,
        stadium,
      ];
    });

  let content = '';
  if (nationalClubs.loading || (data ?? []).length === 0) {
    content = (
      <div className={styles.loader}>
        <Spinner />
      </div>
    );
  } else if (nationalClubs.error) {
    content = (
      <Card softShadow className={styles.errorContainer}>
        <p>{t(nationalClubs.error)}</p>
      </Card>
    );
  } else {
    content = (
      <>
        <Card softShadow className={styles.nationalClubs}>
          <div className={styles.tableContainer}>
            <Table
              flat
              left
              theads={[
                t('athlete_list.box2'),
                t('compe.text1'),
                t('athlete_card.text5'),
                // 'Cidade',
                ...(user.permission_gender !== 'female' ? [t('organi.text12')] : []),
                ...(user.permission_gender === 'female' ? [t('organi.text32')] : [t('organi.text13')]),
                // t('organi.text14'),
                t('text.box19'),
              ]}
              data={data}
              className={styles.table}
            />
          </div>
        </Card>
        {rawData && nationalClubs.data && nationalClubs.data.count > rawData.length && (
          <Pagination
            length={rawData.length}
            count={nationalClubs.data.count}
            pagination={pagination}
            setPagination={setPagination}
            hasNext={nationalClubs.data.hasNext}
            hasPrevious={nationalClubs.data.hasPrevious}
          />
        )}
      </>
    );
  }

  return <>{content}</>;
}

ClubsList.defaultProps = {
  division: 0,
  footlink: false,
};

ClubsList.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  division: PropTypes.number,
  footlink: PropTypes.bool,
};

export default withRouter(ClubsList);
